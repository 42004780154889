/*
 * 由于历史将 $t 直接作为全局的方法，必须在任何业务 js 执行前先确保 i18n 可用
 * 将原本的 main 分为两部分
 * 1. 与 i18n 无关的，尽量提前加载和初始化。小心区分其依赖内部是否引用到 $t()
 * 2. app 入口。包括各 constants 其内部可能也用了 $t）
 * 将增加白屏时间
 */

// 确保排在最前
import '@/mod/aegis';

import { initI18n } from '@/i18n';

const initI18nTask = initI18n();

// 不等待，main-after-pre 里有实际的静态 import
Promise.allSettled([
  // 提前加载的资源名单，尽量减少 main-after-pre 才加载的延迟时间
  import('./main-auth0-plugin'), // auth0 内部有异步请求，尽量早加载
  import('@/styles/element-index.scss'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('@tencent/bk-icon-abetterchoice-v2'),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('@tencent/bk-icon-abetterchoice-v2/src/index'),
  import('vue'),
  import('vue-router'),
  import('pinia'),
  import('element-plus'),
  import('@element-plus/icons-vue'),
  import('@tencent/ti18n-vue3-sdk'),
  import('wujie-vue3'),
]);

(async () => {
  await initI18nTask;
  await import('./main-after-pre');
})();

// 支持 TAB 的 UI 实验，以便产品在 TAB 上体验功能
if (location.host === 'abetterchoice-test.woa.com' && location.search.includes('ui_exp_secret=mjs6o19l')) {
  const htmlString = `<!-- 上报实验数据，可选 -->
<script src="https://beacon.cdn.qq.com/sdk/4.5.9/beacon_web.min.js"></script>
<!-- tab ab实验sdk -->
<script src="https://res.ab.qq.com/tab/3.0.5/tab_jssdk.min.js"></script>
<script type="text/javascript">
  wabt.init({
    appId: "APP_KEY_PLACEHOLDER", // 仅用于调试，不使用真实 id
    guid: "任意字符串形式id", // 未登录时为 uuid 等能识别访客的编号，已登录时为用户 uid
    enableUI: true, // 启用 UI 实验
    onSuccess: function () {
      // 初始化成功回调，可获取相关实验数据
    },
    onFail: function (errMsg) {
      // 初始化失败回调
    },
  });
</script>`;

  const loadOrExecScript = (scriptElement: HTMLScriptElement) => new Promise<void>((resolve, reject) => {
    if (scriptElement.src) {
      scriptElement.onload = function () {
        resolve();
      };
      scriptElement.onerror = function (e) {
        reject(e);
      };
    } else {
      setTimeout(() => {
        resolve();
      }, 0);
    }
    document.head.appendChild(scriptElement);
  });


  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // 将 tempDiv 中的子元素插入到 head 中
  (async () => {
    for (const child of Array.from(tempDiv.children)) {
      if (child.tagName === 'SCRIPT') {
        // 创建新的 script 元素，设置 src 属性
        const newScript = document.createElement('script');
        if ((child as HTMLScriptElement).src) {
          newScript.src = (child as HTMLScriptElement).src; // 设置外部脚本的 URL
        } else {
          newScript.innerHTML = child.innerHTML;
        }
        // 需要串行化，保证 script 按顺序执行
        await loadOrExecScript(newScript);
      } else {
        document.head.appendChild(child); // 其他元素直接插入
      }
    }
  })();
}
